<template>
  <div class="verify-code-container">
    <div class="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 class="text-3xl font-bold text-center mb-6">Admin Verification</h1>
      
      <p class="text-center mb-6">Please enter the 6-digit verification code to access registrations</p>
      
      <div class="code-inputs flex justify-center space-x-2 mb-8">
        <input 
          v-for="(digit, index) in codeDigits" 
          :key="index"
          type="text" 
          maxlength="1"
          v-model="codeDigits[index]"
          @input="onDigitInput(index)"
          @keydown="onKeyDown($event, index)"
          ref="digitInputs"
          class="w-12 h-16 border-2 border-gray-300 rounded-md text-center text-2xl font-bold focus:border-blue-500 focus:outline-none"
        />
      </div>
      
      <div v-if="errorMessage" class="text-red-500 text-center mb-4">
        {{ errorMessage }}
      </div>
      
      <div v-if="connectionError" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
        <p class="font-bold">Connection Issue</p>
        <p>There appears to be a problem connecting to the server. {{ connectionError }}</p>
      </div>
      
      <div class="text-center">
        <button 
          @click="verifyCode" 
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md"
          :disabled="loading"
        >
          <span v-if="loading">
            <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Verifying...
          </span>
          <span v-else>Submit</span>
        </button>
      </div>
      
      <div class="mt-4 text-xs text-center text-gray-500">
        Updated version with CSV export and name split (v2.2)
      </div>
    </div>
    
    <div v-if="showRegistrations" class="max-w-6xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-2xl font-bold">Registrations</h2>
        <button 
          @click="downloadCSV" 
          class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
          Download CSV
        </button>
      </div>
      
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white">
          <thead>
            <tr>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">First Name</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Last Name</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Email</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">City</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">State</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Zip</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Status</th>
              <th class="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium">Nearby Schools</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reg in registrations" :key="reg._id" class="hover:bg-gray-50">
              <td class="py-2 px-4 border-b border-gray-200">{{ getFirstName(reg.name) }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ getLastName(reg.name) }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.email }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.city }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.state }}</td>
              <td class="py-2 px-4 border-b border-gray-200">{{ reg.zip }}</td>
              <td class="py-2 px-4 border-b border-gray-200">
                <span 
                  :class="{
                    'px-2 py-1 rounded text-xs font-medium': true,
                    'bg-green-100 text-green-800': reg.payment_status === 'completed',
                    'bg-yellow-100 text-yellow-800': reg.payment_status === 'pending',
                    'bg-gray-100 text-gray-800': !reg.payment_status
                  }"
                >
                  {{ reg.payment_status || 'Unknown' }}
                </span>
              </td>
              <td class="py-2 px-4 border-b border-gray-200">
                <div v-if="reg.nearby_schools && reg.nearby_schools.length > 0">
                  <button 
                    @click="toggleSchoolsList(reg)" 
                    class="text-blue-600 hover:text-blue-800 text-sm font-medium"
                  >
                    {{ reg.nearby_schools.length }} schools
                    <span>{{ expandedSchools[reg._id] ? '▲' : '▼' }}</span>
                  </button>
                  <div v-if="expandedSchools[reg._id]" class="mt-2 space-y-1">
                    <div 
                      v-for="(school, idx) in reg.nearby_schools.slice(0, 3)" 
                      :key="idx" 
                      class="text-xs"
                    >
                      <span class="font-medium">{{ school.school_name }}</span> 
                      <span class="ml-1">({{ school.city }}) - </span>
                      <span 
                        :class="{
                          'text-green-600': school.distance < 30,
                          'text-yellow-600': school.distance >= 30 && school.distance < 60,
                          'text-red-600': school.distance >= 60
                        }"
                      >
                        {{ school.distance }} miles
                      </span>
                    </div>
                    <div v-if="reg.nearby_schools.length > 3" class="text-xs text-gray-500">
                      And {{ reg.nearby_schools.length - 3 }} more...
                    </div>
                  </div>
                </div>
                <div v-else-if="reg.coordinates">
                  <span class="text-gray-500 text-xs">None within 100 miles</span>
                </div>
                <div v-else>
                  <span class="text-gray-500 text-xs">No geocoding data</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyCodeView',
  props: {
    timestamp: {
      type: Number,
      default: () => new Date().getTime()
    }
  },
  data() {
    return {
      codeDigits: ['', '', '', '', '', ''],
      errorMessage: '',
      connectionError: '',
      loading: false,
      showRegistrations: false,
      registrations: [],
      expandedSchools: {},
      componentVersion: 'v2.2', // For debugging/cache busting
      maxRetries: 3,
      retryCount: 0
    }
  },
  methods: {
    getFirstName(fullName) {
      if (!fullName) return '';
      return fullName.split(' ').slice(0, -1).join(' ') || fullName;
    },
    
    getLastName(fullName) {
      if (!fullName) return '';
      const nameParts = fullName.split(' ');
      return nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
    },
    
    downloadCSV() {
      // Create CSV data
      const headers = ['First Name', 'Last Name', 'Email', 'City', 'State', 'Zip', 'Status'];
      let csvContent = headers.join(',') + '\n';
      
      this.registrations.forEach(reg => {
        const firstName = this.getFirstName(reg.name);
        const lastName = this.getLastName(reg.name);
        const row = [
          this.escapeCSV(firstName),
          this.escapeCSV(lastName),
          this.escapeCSV(reg.email),
          this.escapeCSV(reg.city),
          this.escapeCSV(reg.state),
          this.escapeCSV(reg.zip),
          this.escapeCSV(reg.payment_status || 'Unknown')
        ];
        csvContent += row.join(',') + '\n';
      });
      
      // Create and download file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `camp-registrations-${new Date().toISOString().split('T')[0]}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    
    escapeCSV(value) {
      // Handle null or undefined
      if (value == null) return '';
      
      const stringValue = String(value);
      // Escape quotes and wrap in quotes if needed
      if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
        return '"' + stringValue.replace(/"/g, '""') + '"';
      }
      return stringValue;
    },
    
    async verifyCode() {
      // Combine digits into a single code
      const code = this.codeDigits.join('')
      
      // Check if all digits are filled
      if (code.length !== 6) {
        this.errorMessage = 'Please enter all 6 digits'
        return
      }
      
      this.loading = true
      this.errorMessage = ''
      this.connectionError = ''
      
      try {
        // Add cache-busting parameter
        const cacheBuster = new Date().getTime();
        const response = await fetch(`/api/admin/verify-code?_=${cacheBuster}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
          body: JSON.stringify({ code }),
          timeout: 30000 // 30 second timeout
        })
        
        if (!response.ok) {
          throw new Error(`Server returned ${response.status}: ${response.statusText}`);
        }
        
        const data = await response.json()
        
        if (data.success) {
          this.registrations = data.registrations || []
          this.showRegistrations = true
          this.retryCount = 0
          console.log('Updated VerifyCodeView loaded - version:', this.componentVersion);
          
          if (this.registrations.length === 0) {
            this.errorMessage = 'No registrations found in the database'
          }
        } else {
          this.errorMessage = data.message || 'Invalid code'
        }
        
      } catch (error) {
        console.error('Error verifying code:', error)
        
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
          this.connectionError = 'Unable to connect to the server. Please check your internet connection or try again later.'
          
          // Retry logic for connection issues
          if (this.retryCount < this.maxRetries) {
            this.retryCount++
            this.connectionError += ` Retrying (${this.retryCount}/${this.maxRetries})...`
            
            setTimeout(() => {
              this.verifyCode()
            }, 2000) // Retry after 2 seconds
            return
          }
        } else {
          this.errorMessage = 'An error occurred while verifying the code. Please try again.'
        }
      } finally {
        if (this.retryCount === 0) {
          this.loading = false
        }
      }
    },
    
    toggleSchoolsList(registration) {
      // Toggle expanded state for this registration
      this.expandedSchools = {
        ...this.expandedSchools,
        [registration._id]: !this.expandedSchools[registration._id]
      }
    },
    
    onDigitInput(index) {
      // Move to next input after filling current one
      if (this.codeDigits[index] && index < 5) {
        this.$refs.digitInputs[index + 1].focus()
      }
      
      // Ensure only numbers are entered
      this.codeDigits[index] = this.codeDigits[index].replace(/[^0-9]/g, '')
    },
    
    onKeyDown(event, index) {
      // Handle backspace to move to previous input
      if (event.key === 'Backspace' && !this.codeDigits[index] && index > 0) {
        this.$refs.digitInputs[index - 1].focus()
      }
    }
  },
  mounted() {
    // Focus first input when component mounts
    this.$nextTick(() => {
      if (this.$refs.digitInputs && this.$refs.digitInputs.length > 0) {
        this.$refs.digitInputs[0].focus()
      }
      console.log('VerifyCodeView mounted with timestamp:', this.timestamp);
    })
  }
}
</script>

<style scoped>
.verify-code-container {
  padding: 2rem;
  min-height: 100vh;
  background-color: #f9fafb;
}
</style> 